<template>
  <div>
    <el-row :gutter="24">
      <el-col :span="18" class="hidden-sm-and-down" :offset="4">
        <div class="left">
          <div class="logoBox" style="margin-top: 20px;">
            <img src="../assets/logo.png" alt="" class="logo" @click="toLink('/')">
          </div>
        </div>
        <div class="right" style="width: 50%;line-height: 88px;">
          <el-row>
            <el-col :span="1"></el-col>
            <el-col :span="7" :class="activeIndex == 1 ? 'active menu' : 'menu'">
              <div @click="toLink('/')" style="height: 100%;">首页</div>
            </el-col>
            <el-col :span="1"></el-col>
            <el-col :span="7" :class="activeIndex == 2 ? 'active menu' : 'menu'">
              <div @click="toLink('/business')" style="height: 100%;">传家业务</div>
            </el-col>
            <el-col :span="1"></el-col>
            <el-col :span="7" :class="activeIndex == 3 ? 'active menu' : 'menu'">
              <div @click="toLink('/team')" style="height: 100%;">传家团队</div>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :span='20' class=" hidden-md-and-up" :offset="2">
        <div class="left">
          <div class="logoBox" style="margin-top: 20px;">
            <img src="../assets/logo.png" alt="" class="logo" @click="toLink('/')">
          </div>
        </div>
        <div class="right" style="width: 50%;line-height: 88px;">
          <div class="line-container" v-if="!statusbar" @click="statusbar = true">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
      </el-col>
    </el-row>
    <div v-if="statusbar" style="position: fixed;top: 0;z-index: 100;width: 100%;">
      <div class="top"> <i class="el-icon-close"
          style="font-size: 50px;color:#fff;position: absolute;right: 50px;top: 20px;" @click="statusbar = false"></i>
      </div>
      <div style="margin: 0 auto;width: 200px;margin-top: 74px;">
        <el-row>
          <el-col :span="24" :class="activeIndex == 1 ? 'appActive appMenu' : 'appMenu'">
            <div @click="toLink('/')">首页</div>
          </el-col>
          <el-col :span="24" :class="activeIndex == 2 ? 'appActive appMenu' : 'appMenu'">
            <div @click="toLink('/business')">传家业务</div>
          </el-col>
          <el-col :span="24" :class="activeIndex == 3 ? 'appActive appMenu' : 'appMenu'">
            <div @click="toLink('/team')">传家团队</div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div style="position: relative;">
      <div class="banner hidden-sm-and-down" :style="'background-image:url(' + banner2.picUrl + ');background-position: center center;background-size: cover; background-repeat: no-repeat'">
        <div class="core" :style="'left:'+key+'%'"></div>
        <div class="menuBack"></div>
        <div :class="showIndex == index ? 'lgBack inMenu' : 'inMenu'" v-for="(item, index) in banner" :key="index"
          @mouseenter="handMouseEnter(index)" @mouseleave="handMouseLeave" @click="go2(item)">

          <div  class="banner_content" v-if="showIndex != index">
            <!-- <img :src="item.picUrl" alt="" style="width: 7.5rem;height: 6.625rem;"> -->
            <el-image style="width: 7.5rem;height: 6.625rem;" :src="item.picUrl" fit="contain"></el-image>
            <div class="text">{{ item.title }}</div>
            <!-- <div v-if="showIndex == index" class="text_box">
              {{ item.briefIntro }}
              <div
                style="cursor: pointer;;margin-top: 80px;background-color: #FEF5E8;width: 50%;border-radius: 50px; color: #3E302D;font-size: 1.5rem;line-height: 50px;text-align: center;margin-left:auto;margin-right:auto;">
                了解详情</div>
            </div> -->
          </div>
          <div  class="banner_contentHave"  :style="showIndex == index ?'top: 50%;':'top:200%'">
            <!-- <img :src="item.picUrl" alt="" style="width: 7.5rem;height: 6.625rem;"> -->
            <el-image style="width: 7.5rem;height: 6.625rem;" :src="item.picUrl" fit="contain"></el-image>
            <div class="text">{{ item.title }}</div>
            <div  class="text_box">
              {{ item.briefIntro }}
              <div
                style="cursor: pointer;;margin-top: 80px;background-color: #FEF5E8;width: 50%;border-radius: 50px; color: #3E302D;font-size: 1.5rem;line-height: 50px;text-align: center;margin-left:auto;margin-right:auto;">
                了解详情</div>
            </div>
          </div>
        </div>

      </div>
     
    </div>
    <div class="appBanner hidden-md-and-up">
      <img :src="banner2.appUrl" alt="" style="width:100%;">
      <el-carousel trigger="click" height="310px" indicator-position='none' :autoplay="false" arrow="always"
        style="margin-top: -4px;">
        <el-carousel-item v-for="(item, index) in banner" :key="index">
          <div class="box-banner">
            <div style=" padding: 20px 55px 30px 55px;color: #fff;font-weight: bold;">
              <div style="font-size: 36px;">{{ item.title }}</div>
              <div style="font-size: 1rem;margin-top: 10px;max-height: 150px;min-height: 100px;overflow-y: auto;">
                {{ item.briefIntro }}
              </div>
              <div @click="go(item.skipUrl)"
                style="background-color: #FEF5E8;padding: 10px 30px;margin-top: 20px;color: #3E302D;width: 100px;text-align: center;border-radius: 50px;">
                了解详情
              </div>
            </div>

          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="pinpai" id="target-id" style="">
      <el-row :gutter="24">
        <el-col :span="18" :offset="3">
          <el-row>
            <el-col :span="8" class="hidden-sm-and-down">
              <!-- <img src="../assets/chuanjia.png" style="width: 100%;" alt=""> -->
              <el-image style="width: 100%;border-radius:20px;" :src="band.picUrl" fit="contain"></el-image>
            </el-col>
            <el-col :span="15" :xs="24" :offset="1">
              <div class="title hidden-sm-and-down" style="color: #3E302D;font-size: 53px;font-weight: bold;">{{
                band.title }}</div>
              <div class="title hidden-md-and-up" style="color: #3E302D;font-size: 26px;font-weight: bold;">{{
                band.title }}</div>
              <div class=" hidden-sm-and-down"
                style="background-color: #D61C2E;width: 91px;height: .6rem;border-radius: 7px;margin-top: 18px;">
              </div>
              <div class="hidden-md-and-up"
                style="background-color: #D61C2E;width: 91px;height: .3rem;border-radius: 7px;margin-top: 18px;">
              </div>
              <div style="margin-top: 28px;font-size: 18px;font-weight: 400;color: #3E302D;line-height: 35px;" v-html=" band.content">
               
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div class="footer hidden-sm-and-down">
      <el-row>
        <el-col :span="13" :offset="8">
          <div style="display:flex;justify-content: space-between;">
            <div style="max-width: 167px;height: 126px;margin-top: 63px; float: left;">
              <img src="../assets/logo.png" alt="" style="width: 100%;height: 126px;">
            </div>
            <div
              style="float: right;font-weight: bold;font-size: 36px;margin-top: 24px;margin-bottom: 24px;">
              <div>联系我们</div>
              <div style="width: 26rem;height: 2px; background-color:#000;margin-top: 12px;margin-bottom: 12px;"></div>
              <div style="margin-bottom:12px ;font-size: 22px;font-weight: 400;">联系电话：{{ about.phone }} </div>
              <div style="margin-bottom:12px ;font-size: 22px;font-weight: 400;">官方邮箱：{{ about.email }}</div>
              <div style="margin-bottom:12px ;font-size: 22px;font-weight: 400;">公司地址：{{ about.address }}</div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div style="width: 100%;background-color: #2C2726;height: 60px;">
        <el-row>
          <el-col :span="16" :offset="8">
            <div
              style="float: left;color: #FFFFFF;font-size: 16px;font-weight: 400;line-height: 60px;margin-left:-42px ;">
              ©2024 北京传承家办管理咨询有限公司</div>
            <div
              style="float: left;color: #FFFFFF;font-size: 16px;font-weight: 400;margin-left: 102px;line-height: 60px;">
              备案：京ICP备2024066754号-2</div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="footerApp  hidden-md-and-up" >
        <div :span="24" style="text-align: center;">
          <div style="font-size: 1rem;font-weight: bold">联系我们</div>
          <div style="width: 100%;margin: 0 auto;height: 2px;background-color:#D2041A  ;margin-top: 12px;margin-bottom: 12px;"></div>
          <div style="margin-bottom:12px ;font-size: 1rem;font-weight: 400;">联系电话：{{ about.phone }}</div>
          <div style="margin-bottom:12px ;font-size: 1rem;font-weight: 400;">官方邮箱：{{ about.email }}</div>
          <div style="margin-bottom:12px ;font-size: 1rem;font-weight: 400;">公司地址：{{ about.address }}</div>
        </div>
        <div :span="24" style="width: 100%;background-color: #2C2726;position: absolute;bottom: 0;">
          <div style="color: #FFFFFF;font-size: .8rem;font-weight: 400;text-align: center;margin-top: 20px;">©2024 北京传承家办管理咨询有限公司</div>
          <div style="color: #FFFFFF;font-size: .8rem;font-weight: 400;line-height: 45px;text-align: center">备案：京ICP备2024066754号-2</div>
        </div>
      </div>
  </div>
</template>

<script>

export default {
  name: 'home',
  data() {
    return {
      activeIndex: 1,
      statusbar: false,
      picUrl: '',
      showIndex: 0,
      banner: [],
      band: {},
      about: {},
      banner2: {},
      key:0
    };
  },
  mounted() {
    this.getData()
  },
  methods: {
  go2(e){
    console.log(e)
    if(e.id == "1845733074573471745"){
      const element = document.getElementById('target-id');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }else{
      if(e.skipUrl){
        window.open(e.skipUrl)
      }else{
        this.$message({
          message: '相关内容建设中',
          type: 'warning'
        });
      }
      
    }
    
  },
    go(e) {
      window.open(e)
    },
    handMouseLeave() {
      console.log('移出')
    },
    handMouseEnter(e) {
      console.log('移入',e)
      this.showIndex = e
      if(e == 0){
        this.key = 0
      }
      if(e == 1){
        this.key = 13.9
      }
      if(e == 2){
        this.key = 27.8
      }
      if(e == 3){
        this.key = 41.6777
      }
      if(e == 4){
        this.key = 55.6
      }
      if(e == 5){
        this.key = 69.5
      }
    },
    getData() {
      this.$http.get('api/desk/banner-list?detailType=3').then(res => {
        if (res.code == 200) {
          this.banner2 = res.data[0]
        }
      })
      this.$http.get('api/desk/banner-top-list').then(res => {
        console.log(res.data)
        if (res.code == 200) {
          this.banner = res.data
        }
      })
      this.$http.get('api/desk/banner-band').then(res => {
        if (res.code == 200) {
          this.band = res.data
        }
      })
      this.$http.get('api/desk/contact-us').then(res => {
        if (res.code == 200) {
          this.about = res.data
        }
      })
    },
    toLink(url) {
      console.log(url)
      this.$router.push({ path: url })
      this.statusbar = false
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    }
  }
}
</script>
<style>
.banner {
  position: relative;
  z-index: 1;
}

.core {
  position: absolute;
  left: 0;
  width: 30.5% !important;
  height: 100%;
  z-index: 3;
  background-color: #D2041A !important;
  transition: all .5s;
  z-index: 1;
}

.box-banner {
  background-color: #D2041A;
  width: 100%;
  height: 310px;

}

.appBanner {
  margin-top: 1rem;
}

.footer {
  width: 100%;
  height: 16.6rem;
  background: url('../assets/footer.jpg') no-repeat;
  background-size: 100% 100%;
  padding-top: 1rem;
  position: relative;
}

.footerApp {
  width: 100%;
  height: 15rem;
  background: url('../assets/button-app.jpg') no-repeat;
  background-size: 100% 100%;
  padding-top: 1rem;
  position: relative;
}

.pinpai {
  padding-top: 5rem;
  margin-bottom: 10rem;
}

@media screen and (max-width: 768px) {
  .pinpai {
    margin-top: 2rem;
    margin-bottom: 5rem;
  }
}

.banner {
  width: 100%;
  height: 91vh;

  display: flex;
  justify-content: space-between;
}
@keyframes changeBg{
    0%{
      /* background-color: #D2041A; */
    }
    100%{
      /* background-color: #D2041A; */
    }
}

.lgBack {
  width: 33% !important;
  /* transition: width .1s; */
  /* border-right: 0px solid #FEF5E8 !important; */
  position: relative;
  z-index: 1;
  
  /* background-color: rgba(0, 0, 0, 0) !important; */
  /* background-color: #D2041A !important; */
  animation: changeBg .5s infinite;
 
}

.inMenu {
  width: 15%;
  height: 100%;
  border-right: 1px solid rgba(255,255,255,0.5);
  position: relative;
  transition: show .4s;
  top: 0;
  overflow: hidden;
}
.menuBack{
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, .5);
}

.inMenu:nth-child(1) {
  margin-left: 0;
}


.banner_contentHave {
  width: 75%;
  height: 400px;
  position: absolute;
  top: 200%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  transition: all .5s;
}

.banner_contentHave img {
  width: 84px;
  height: 73px;
}

.banner_contentHave .text {
  font-size: 36px;
  font-weight: bold;
  /* margin-top: 30px; */
  color: #fff;
  position: relative;
  top: 30px;
}

.banner_contentHave .text_box {
  font-size: 24px;
  font-weight: bold;
  /* margin-top: 30px; */
  color: #fff;
  position: relative;
  top: 50px;
  text-align: left;
}

.inMenu .banner_content {
  width: 100%;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  text-align: center;
}

.banner_content img {
  width: 84px;
  height: 73px;
}

.banner_content .text {
  font-size: 36px;
  font-weight: bold;
  /* margin-top: 30px; */
  color: #fff;
  position: relative;
  top: 30px;
}
</style>
<style>
#app {
  overflow: hidden;
}

.top {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.line-container {
  margin-top: 25px;
  float: right;
}

.line {
  width: 35px;
  /* 横线的宽度 */
  height: 2px;
  /* 横线的高度 */
  margin-top: 10px;
  background-color: #3E302D;
  /* 横线的颜色 */
}

.line:not(:last-child) {
  margin-right: 10px;
  /* 横线之间的间隔 */
}

.appMenu {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  position: relative;
  cursor: pointer;
  margin-bottom: 50px;
}

.appActive::after {
  content: "";
  display: block;
  width: 53px;
  height: 4px;
  background: #F4AD05;
  /* 边框的颜色 */
  position: absolute;
  left: 50%;
  margin-top: 10px;
  border-radius: 2px;
  transform: translateX(-50%);
}

.menu {
  color: #3E302D;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  position: relative;
  cursor: pointer;
}

.active::after {
  content: "";
  display: block;
  width: 53px;
  height: 4px;
  background: #F4AD05;
  /* 边框的颜色 */
  position: absolute;
  left: 50%;
  margin-top: -18px;
  border-radius: 2px;
  transform: translateX(-50%);
}

.left {
  float: left;
}

.right {
  float: right;
}

.logoBox {
  width: 74px;
  height: 54px;
}

.logo {
  width: 100%;
  height: 54px;
  line-height: 88px;
  cursor: pointer;
}

.el-menu--horizontal>.el-menu-item.is-active {

  border-bottom: 2px solid #F4AD05 !important;
}
</style>
<style >
.ql-size-small{
  font-size: 12px !important;
}
.ql-size-larg{
  font-size: 24px !important;
}
.ql-size-huge{
  font-size: 32px !important;
}
</style>